.buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%
}

.button {
  padding: 25px 15px;
  margin-top: 15px;
  margin-bottom: 25px;
  border-color: #ddd;
  border-radius: 100px;
  color: white;
  text-transform: uppercase;
  width: 90%;
  align-self: center;
  border: none;
  cursor: pointer;
}

.formWrapper {
  width: 100%;
  position: 'absolute';
  bottom: 0
}

.inlineButtons {
  align-content: center;
  align-self: center;
  background-color: transparent;
  width: auto;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 670px) {
  .button {
    width: 100%;
  }
}