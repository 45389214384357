.wrapper {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background-color: transparent
}

.touchableWrapper {
  width: 100%;
  height: 100%
}

.sliderWrapper {
  background-color: transparent;
  left: 50%;
  z-index: 999999;
  padding: 37.5px 0px 37.5px 0px;
  position: absolute;
  transform: translateX(-50%);
  top: 0;
  width: 80%;
}

.slider {
  height: 20px;
  width: 20px;
  margin-top: -7px;
  margin-left: -10px;
  border-radius: 5px;
  position: absolute;
  z-index: 999;
}

.sliderBg {
  height: 5px;
  border-radius: 5px;
  width: 100%;
  z-index: 998;
}

.sliderFg {
  height: 5px;
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  margin-top: 37.5px;
  border-radius: 5px;
}

.playbackButtonWrapper {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: 20px;
}

.divButtonWrapper {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 895px) {
  .sliderWrapper {
    background-color: transparent;
    left: 50%;
    z-index: 999999;
    padding: 37.5px 0px 37.5px 0px;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .sliderWrapper {
    background-color: transparent;
    left: 50%;
    z-index: 999999;
    padding: 37.5px 0px 37.5px 0px;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 40%;
  }
}