.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: none;
}

.journalTitle {
  position: absolute;
  color: #4DC8B7;
  font-weight: 500;
  width: 70%;
  left: 15%;
  font-size: 30px;
}

.textScrollView {
  width: 100%;
  height: 90%;
  position: absolute;
  top: 10%;
  left: 0;
  overflow: scroll;
}

.journalText {
  text-align: left;
  font-size: 20px;
  margin-left: 20px;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
}