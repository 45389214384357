.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #C4C4C4;
}

.image {
  height: 100%;
  background-size: contain;
  position: absolute;
  top: 0;
  background-color: #FFFFFF;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 670px) {
  .image {
    height: unset;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
  }
}