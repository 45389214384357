.memoryWrapper {
  width: 168px;
  position: relative;
  height: 240px;
  border-radius: 30px;
  display: inline-block;
  box-shadow: 2px 2px 5px 1px #C4C4C4;
  background-color: #FFFFFF;
  margin: 20px 12px 0px 12px;
  .memoryCreatedAt {
    position: absolute;
    bottom: 0;
    margin: 0px 0px 5px 0px;
    font-size: 8px;
    width: 100%;
    text-align: center;
    opacity: 0.54;
    color: #263238;
  }
  .memoryImagePreview {
    background-color: #C4C4C4;
    height: 152px;
    width: 168px;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .memoryLockIconWrapper {
    background-color: #C4C4C4;
    height: 152px;
    width: 168px;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    .memoryLockIcon {
      font-size: 22px;
      position: absolute;
      color: #4DC8B7;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .memoryInfoWrapper {
    width: 168px;
    height: 88px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    .memoryTitle {
      font-weight: 400;
      font-size: 18px;
      position: absolute;
      width: 90%;
      top: 0;
      left: 5%;
      text-align: center;
      margin: 20px 0px 0px 0px;
    }
    .memoryDescription {
      opacity: 0.54;
      color: #263238;
      font-size: 8px;
      text-align: left;
      position: absolute;
      left: 5%;
      width: 90%;
      margin: 48px 0px 0px 0px;
    }
  }
}