.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.aboutUsWrapper {
  width: 80%;
  height: 100%;
  position: absolute;
  left: 10%;
  top: 0;
  background-color: transparent;
  p {
    text-align: center;
    margin: 0px 0px 40px 0px;
  }
}

.aboutUsVideoText {
  text-align: center;
  a {
    text-decoration: none;
    color: #4DC8B7;
  }
}

.header {
  margin: 20px 0px 20px 0px;
}