.textField {
  width: 90%;
  height: 70px;
  color: #3C4852;
  font-size: 24px;
  background-color: white;
  border-radius: 100px;
  border-width: 2px;
  text-align: center;
  z-index: 0;
  margin-bottom: 5px
}

@media only screen and (max-width: 670px) {
  .textField {
    width: 100%;
  }
}