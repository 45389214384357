.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
}

.bucketsListWrapper {
  width: 100%;
  height: calc(100vh - 110px);
  position: absolute;
  top: 50px;
  left: 0;
  margin: 0px 0 0 0;
  overflow-y: scroll;
}

.userBucketsText {
  position: absolute;
  left: 25%;
  font-size: 18px;
  color: #D7D7D7;
  padding: 5px 5px 5px 5px;
  border: none;
  width: 112px;
  margin: 0;
}

.activeBucketsText {
  border-bottom: 2px solid #4DC8B7 !important;
  color: #4DC8B7;
}

.receivedBucketsText {
  position: absolute;
  right: 25%;
  font-size: 18px;
  color: #D7D7D7;
  padding: 5px 5px 5px 5px;
  border-bottom: none;
  width: 152px;
  margin: 0;
}

.bucketListInner {
  width: 100%;
  height: 100%;
}

.bucketsLoadingSpinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  margin: 20px 0 0 0;
}

.centered {
  width: 700px;
  position: absolute;
  left: 0;
  text-align: center;
  top: 0;
  height: 100%;
}

.bucketWrapper {
  width: 110px;
  display: inline-block;
  flex-direction: row;
  height: 180px;
  background-color: transparent;
  position: relative;
  margin: 15px 38px 15px 38px;
}

.bucketImageWrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 110px;
  height: 110px;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    background-color: #C4C4C4;
  }
}

.tealBucketIcon {
  width: 22px;
  height: 18px;
  margin: 132px 0px 0px 0px;
  position: absolute;
}

.bucketHeaderTitle {
  font-weight: bold;
  font-size: 18px;
  margin: 34px 0px 0px 138px;
  position: absolute;
  left: 0;
  top: 0;
}

.tealBucketIconHeader {
  width: 22px;
  height: 18px;
  margin: 39px 0px 0px 96px;
  position: absolute;
}

.folderAssignedUsersTextWrapper {
  bottom: 0;
  left: 0;
  height: 10px;
  position: absolute;
}

.bucketButtonsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 54px;
}

.folderAssignedUsersText {
  font-size: 8px;
  margin: 0;
  color: #263238;
  opacity: 0.54;
}

.bucketInner {
  width: 90%;
  height:  100%;
  left: 50%;
  top: 0;
  position: absolute;
  transform: translateX(-50%);
}

.bucketTitle {
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  left: 0;
  margin: 130px 0px 0px 37px;
  height: 20px;
  overflow: hidden;
  text-align: left;
  width: 66%;
}

.lockIcon {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 40px;
}

.bucketTitle:hover {
  cursor: pointer;
}


.bucketViewUploadListWrapper {
  width: 100%;
  height: calc(100vh - 150px);
  position: absolute;
  top: 0;
  left: 0;
  margin: 90px 0 0 0;
  overflow-y: scroll;
}

.bucketViewHeaderWrapper {
  width: 100%;
  height: 90px;
  position: absolute;
  top: 0;
  box-shadow: 2px 2px 1px #C4C4C4;
  left: 0;
  .bucketImageWrapper {
    right: 25px;
    left: unset;
  }
}

.bucketProfileImage {
  width: 50px;
  height: 50px;
  background-color: #C4C4C4;
  border-radius: 13px;
  position: absolute;
  left: 29px;
  top: 24px;
}

.bucketUserPath {
  position: absolute;
  left: 0;
  top: 0;
  margin: 30px 0px 0px 30px;
}

.bucketUserName {
  position: absolute;
  right: 0;
  top: 0;
  margin: 30px 110px 0px 0px;
}

.bucketUserEmail {
  position: absolute;
  right: 0;
  top: 0;
  margin: 50px 110px 0px 0px;
}

.uploadListInner {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .userBucketsText {
    left: 10%;
  }
  .receivedBucketsText {
    right: 10%;
  }
}