$button-color: #185d84;
$paragraph-color: #506778;
$heading-color: #333;

@font-face {
  font-family: NowayBold;
  src: local('NowayBold'), url(./assets/fonts/Noway-Bold.otf) format('opentype');
}

@mixin acumin-light {
  font-family: acumin-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin acumin-regular {
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin acumin-semibold {
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin acumin-bold {
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin acumin-condensed-semibold {
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin title {
  font-family: "Overlock", cursive;
  font-weight: 400;
}

@mixin title-semibold {
  font-family: "Overlock", cursive;
  font-weight: 700;
}

@mixin title-bold {
  font-family: "Overlock", cursive;
  font-weight: 900;
}

@mixin transitioned {
  -webkit-transition: 300ms ease-out;
  -moz-transition: 300ms ease-out;
  -o-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
