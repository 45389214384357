.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  background-color: #FFFFFF;
  top: 0;
  overflow-y: scroll;
  left: 0;
}

.navLogo {
  position: absolute;
  left: 20px;
  top: 18px;
  width: 170px;
  height: 150px;
}

@media only screen and (max-width: 1100px) {
  .imagePreview {
    height: 60vh !important;
    position: absolute;
    left: 0 !important;
  }

  .landingTextWrapper {
    width: 50% !important;
    height: 100% !important;
    right: 0 !important;
    top: 0 !important;
    position: absolute;
  }
  .landingTitle {
    font-size: 4vh !important;
  }
  .landingAppButtonsWrapper {
    width: 90% !important;
    height: 4.6vh !important;
    top: unset !important; 
  }
}

@media only screen and (max-width: 1186px) {
  .imagePreview {
    height: 60vh !important;
    position: absolute;
    left: 0 !important;
  }

  .landingAppIntroVideo {
    position: absolute;
    left: 5% !important;
    border: 1px solid;
    top: 20% !important;
    width: 40vw !important;
  }

  .navLogo {
    position: absolute;
    left: 20px;
    top: 18px;
    width: 80px;
    height: 75px;
  }

  .landingTextWrapper {
    width: 50% !important;
    height: 100% !important;
    right: 0 !important;
    top: 0 !important;
    position: absolute;
  }
  .landingTitle {
    font-size: 4vh !important;
  }
  .landingAppButtonsWrapper {
    width: 90% !important;
    height: 4.6vh !important;
    top: unset !important; 
  }
}

@media only screen and (max-width: 650px) {
  .imagePreview {
    height: 80vh !important;
    position: absolute;
    left: 0 !important;
  }

  .landingTextWrapper {
    width: 80% !important;
    height: 80% !important;
    right: 10% !important;
    top: 63vh !important;
    position: absolute;
  }
  .landingTitle {
    font-size: 4vh !important;
  }
  .landingAppButtonsWrapper {
    width: 90% !important;
    height: 4.6vh !important;
    top: unset !important; 
  }


.landingAppIntroVideo {
  position: absolute;
  left: 10vw !important;
  border: 1px solid;
  top: 25% !important;
  width: 80vw !important;
}
}

.landingWebFeaturesComingSoonText {
  width: 100%;
  margin-top: 10vh;
  font-size: 3vh;
  font-weight: bold;
  font-style: italic;
  color: #000000;
}

.imagePreview {
  height: 100%;
  position: absolute;
  left: 10%;
}

.landingAppIntroVideo {
  position: absolute;
  left: 15%;
  border: 1px solid;
  top: 16%;
  width: 35vw;
}

.landingTitle {
  font-size: 5vh;
  color: #000000;
  text-align: left;
  font-weight: bold;
}

.landingSubText {
  font-size: 2.3vh;
  text-align: left;
}

.turqoiseText {
  color: #4DC8B7;
}

.landingAppText {
  margin-top: 3vh;
  font-size: 2.3vh;
  text-align: left;
}

.landingAppButtonsWrapper {
  position: absolute;
  top: 70vh;
  width: 100%;
  height: 6.4vh;
} 

.googlePlayButton {
  left: 0;
  height: 100%;
  position: absolute;
}

.appStoreButton {
  right: 0;
  height: 100%;
  position: absolute;
}

.landingTextWrapper {
  width: 35.7%;
  height: 90%;
  right: 10%;
  top: 8%;
  position: absolute;
}