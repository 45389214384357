.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
}


.memoryInfoHeader {
  width: 100%;
  height: 60px;
  box-shadow: 2px 2px 1px #C4C4C4;
}

.memoryTitle {
  font-size: 18px;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px 0px 0px 50px;
}

.memoryAssetListWrapper {
  width: 100%;
  position: absolute;
  left: 0;
  overflow-y: scroll;
  bottom: 0;
  height: calc(100vh - 120px);
}

.memoryAssetListWrapperInner {
  width: 90%;
  height: 100%;
  top: 0;
  left: 5%;
  position: absolute;
}

.memoryDescription {
  position: absolute;
  font-size: 8px;
  top: 0;
  left: 0;
  color: #263238;
  opacity: 0.54;
  margin: 35px 0px 0px 50px;
}

.memoryStartDate {
  position: absolute;
  color: #263238;
  opacity: 0.54;
  font-size: 8px;
  top: 15px;
  right: 20px; 
}