@import "../../mixins";

.appHeaderContainer {
  position: absolute;
  width: 100%;
  height:  60px;
  background-color: #4DC8B7;
  top: 0;
  left: 0;
  margin: 0 0 60px 0;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.1);
}

.appHeaderSignOutIcon {
  position: absolute;
  font-size: 24px;
  color: #333;
  opacity: 0.8;
  right: 0;
  margin: 19px 20px 0px 0px;
}


.appHeaderSignOutIcon:hover {
  opacity: 1;
  cursor: pointer;
}

.appHeaderInner {
  position: absolute;
  width: 80%;
  height:  60px;
  margin: 0 0 0 10%;
  top: 0;
  z-index: 999;
  left: 0;
}

.svgTypographyWrapper {
  width: 190px;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0%;
  transform: translateX(-50%);
  left: 50%;
}



.svgLogoWrapper {
  width: 40px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 10px 0px 0px 150px;
}

.hamburgerMenuIcon {
  display: block;
  position: absolute;
  left: 0;
  font-size: 30px;
  top: 0;
  z-index: 1000;
  margin: 15px 0px 0px 50px;
  color: white;
}

.navLogo {
  position: absolute;
  right: 70px;
  top: 18px;
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1420px) {
  .navLogo {
    position: absolute;
    right: 60px;
    top: 18px;
    width: 20px;
    height: 20px;
  }
  .svgTypographyWrapper {
    right: 90px;
  }
}

@media only screen and (max-width: 1140px) {
  .navLogo {
    position: absolute;
    right: 50px;
    top: 18px;
    width: 20px;
    height: 20px;
  }
  .svgTypographyWrapper {
    right: 80px;
  }
}

@media only screen and (max-width: 495px) {
  .navLogo {
    position: absolute;
    right: 30px;
    top: 18px;
    width: 20px;
    height: 20px;
  }
  .svgTypographyWrapper {
    width: 110px;
    height: 60%;
    position: absolute;
    z-index: 1000;
    transform: translateX(-50%);
    left: 50%;
    top: 20%;
  }
}

.appLink {
  float: right;
  height: 100%;
  position: relative;
  font-family: NowayBold;
  line-height: 60px;
  padding: 0 10px 0 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: $heading-color;
  a {
    color:inherit;
    font-size:13px;
    line-height:18px;
    @include title-bold();
  }
}

@media only screen and (max-width: 850px) {
  .navLogo {
    position: absolute;
    right: 40px;
    top: 18px;
    width: 20px;
    height: 20px;
  }
  
  .appLink {
    display: none;
  }
  .appHeaderSignOutIcon {
    display: none;
  }
  .hamburgerMenuIcon {
    display: block;
  }
  .appHeaderInner {
    width: 80% !important;
    margin: 0 0 0 10% !important; 
  }
  .selectorElem {
    display: none !important;
  }
}

@media only screen and (max-width: 1140px) {
  .appHeaderInner {
    width: 100%;
    margin: 0;
  }
}