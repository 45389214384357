.uploadWrapper {
  width: 75%;
  height: 120px;
  position: relative;
  left: 12.5%;
  margin: 20px 0px 20px 0px;
}

.uploadInner {
  width: 80%;
  height:  100%;
  left: 50%;
  top: 0;
  position: absolute;
  transform: translateX(-50%);
}

.uploadFileName {
  width: 100%;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 8px;
  color: #263238;
  text-align: left;
  opacity: 0.54;
  margin: 0px 0px 0px 20px;
  height: 20px;
}

.memoryImageAssetPreview {
  width: 119px;
  height: 88px;
  background-color: #C4C4C4;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  .thumbnail {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    left: 0;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.6;
    position: absolute;
    z-index: 999;
    border-radius: 20px;
    left: 0;
  }
  .expandIcon {
    position: absolute;
    z-index: 1000;
    font-size: 20px;
    color: #FFFFFF;
    right: 10px;
    top: 10px;
  }
}

.memoryAssetTextPreview {
  width: 119px;
  height: 88px;
  background-color: #C4C4C4;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.6;
    position: absolute;
    z-index: 999;
    border-radius: 20px;
    left: 0;
  }
  .textFileIcon {
    position: absolute;
    z-index: 1000;
    font-size: 40px;
    color: #FFFFFF;
    left: 45px;
    top: 22px;
  }
}

.memoryAudioAssetPreview {
  border-radius: 20px;
  background-color: #4DC8B7;
  position: relative;
  height: 88px;
  width: 211px;
  .playbackIcon {
    position: absolute;
    top: 27px;
    font-size: 25px !important;
    left: 17px;
  }
  .audioWavesIcon {
    height: 68px;
    width: 143px;
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

.memoryVideoAssetPreview {
  width: 119px;
  height: 88px;
  background-color: #C4C4C4;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  .thumbnail {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    left: 0;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.6;
    position: absolute;
    z-index: 999;
    border-radius: 20px;
    left: 0;
  }
  .playIcon {
    position: absolute;
    z-index: 1000;
    width: 40px;
    height: 40px;
    left: 39.5px;
    top: 22px;
  }
}

.uploadFileName:hover {
  cursor: pointer;
}

.recipientUploadFileName {
  width: 60%;
  line-height: 120px;
  text-overflow: ellipsis;
  position: relative;
  top: 0;
  left: 0;
  margin: 0px 0px 0px 40px;
  float: left;
}

.uploadFileIcon {
  font-size: 32px;
  line-height: 96px;
  color: black;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  margin: 0px 0px 0px 40px;
  float: left;
}

.uploadFileIcon:hover {
  cursor: pointer;
}

.uploadDeleteIcon {
  font-size: 32px;
  line-height: 96px;
  color: black;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
}

.uploadDeleteIcon:hover {
  cursor: pointer;
  color: grey;
}

.uploadFileImage {
  float: left;
}

.uploadFileImage:hover {
  cursor: pointer;
}

.uploadPlaybackIcon {
  font-size: 32px;
  line-height: 96px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0 40px 0 0;
  position: absolute;
}