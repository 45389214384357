.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.givingWrapper {
  width: 80%;
  height: 100%;
  position: absolute;
  left: 10%;
  top: 0;
  background-color: transparent;
  p {
    margin: 0px 0px 40px 0px;
    text-align: center;
  }
  .givingImageOne {
    position: relative;
    float: left;
    margin-left: -7vw;
    margin-top: -5vh;
    border-radius: 15px;
    width: 15vw;
  }

  .givingImageTwo {
    position: relative;
    float: right;
    margin-right: -7vw;
    margin-top: -5vh;
    width: 15vw;
    border-radius: 15px;
  }

  .givingImageThree {
    position: relative;
    float: center;
    width: 15vw;
    border-radius: 15px;
  }

  
  .givingImageFooter {
    position: absolute;
    left: 0;
    margin-top: 40px;
    height: 15vw;
    width: 100%;
    img {
      height: 100%;
      width: unset;
      border-radius: 30px;
    }
    .givingImageFour {
      position: relative;
      margin-left: -7vw;
      float: left;
    }
    .givingImageFive {
      position: relative;
    }
    .givingImageSix {
      position: relative;
      float: right;
      margin-right: -7vw;
    }
  }
}

.header {
  margin: 20px 0px 20px 0px;
}

.givingLink {
  color: #4DC8B7;
  font-size: 22px;
  text-align: center;
  margin: 20px 0px 20px 0px;
}