.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  background-color: #333333;
  top: 0;
  text-align: left;
  padding: 5%;
  left: 0;
}

.header {
  text-align: center;
}