@import url("https://use.typekit.net/yae5acb.css");
@import url("https://fonts.googleapis.com/css?family=Overlock:400,700,900&display=swap");
@import "mixins";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}

body {
  @include acumin-regular();
  background-color: #fff;
}

form {
  margin: 0 auto;
  input {
    margin: 0 auto
  }
}

* {
  box-sizing: border-box;
}

h1 {
  @include title-bold();
}

h2,
h3,
h4,
h5,
h6,
strong {
  @include acumin-condensed-semibold();
}

h2 {
  font-size: 60px;
  line-height: 68px;
}

p {
  @include acumin-regular();
}
