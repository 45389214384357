.videoContainer {
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
  margin: -80px 0px 0px 0px;
}

.closeIcon {
  position: absolute;
  left: 20px;
  top: 20px;
}

.hideButton { display: none !important; }

@media only screen and (max-width: 670px) {
  .videoContainer {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
    margin: -20px 0px 0px 0px;
  }
}