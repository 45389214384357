@import "mixins";

.App {
  text-align: center;
}

.appWrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}
.appWrapperActive {
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  height: 100%;
  overflow: hidden;
}

footer {
  display: block;
  padding: 40px 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
  a {
    color: inherit;
    padding: 0;
    margin: 0 12px 0 0;
  }
}

section {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.main {
  padding: 80px 50px 50px 50px;
  margin: 0;
  display: block;
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  margin: 60px 0px 0px 0px;
  text-align:center; 
}

@media only screen and (max-width: 670px) {
  .main {
    padding: 20px 50px 50px 50px;
  }
  .appWrapperActive {
    left: 70%;
  }
}