.playbackButton {
  color: black;
  width: 32px;
  font-size: inherit;
  position: relative;
}

.playbackButton:hover {
  color: grey;
  cursor: pointer;
}

.playbackButtonDisabled {
  color: grey;
  width: 32px;
  font-size: inherit;
  position: relative;
}