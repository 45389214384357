.wrapper {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 999999;
}

.inner {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.2;
  left: 0;
  top: 0;
  position: absolute;
}

.loadingSpinner {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 200px;
  color: black;
}

.loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translateX(-45%) translateY(-45%);
  font-size: 200px;
  margin: -60px 0px 0px 0px;
  color: black;
}

.loadingLogo {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translateX(-45%) translateY(-45%);
  height: 100px;
  width: 100px;
  margin: 0px 0px 0px 5px;
  color: black;
}

.loadingMsgText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 32px;
  color: black;
}