.passwordTooltip {
  align-self: flex-end;
  position: absolute;
  top: 23px;
  right: 22px;
  width: 20px !important;
  height: 20px !important;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px 80px 0px 0px;
  &:hover {
    color: #333;
    cursor: pointer;
  }
}

@media only screen and (max-width: 670px) {
  .passwordTooltip {
    margin: 0;
  }
}