.sidebarWrapper {
  position: absolute;
  background-color: #424242;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
}

.sidebarUserProfile {
  width: 110px;
  height: 110px;
  background-size: contain;
  position: absolute;
  left: 50%;
  border-radius: 30px;
  transform: translateX(-50%);
}

.profileFirstName {
  text-align: center;
  position: absolute;
  margin: 120px auto 0px 0px;
  color: white;
  font-size: 32px;
  transform: translateX(-50%);
  left: 50%;
}

.appUpperLinksWrapper {
  top: 210px;
  position: relative;
  .appLink {
    a {
      text-align: left !important;
      margin: 0 !important;
    }
  }
}

.appLowerLinksWrapper {
  position: relative;
  top: 240px;
  .appLink {
    a {
      text-align: left !important;
      margin: 0 !important;
    }
  }
}

.appLinkWrapperDivider {
  background-color: #D7D7D7;
  width: 100%;
  height: 1px;
  float: left;
  top: 230px;
  position: relative;
}

.sidebarInner {
  height: 90%;
  width: 90%;
  position: absolute;
  top: 5%;
  left: 5%;

  .closeIcon {
    font-size: 25px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .appLink {
    width: 80%;
    margin: 0px 0px 0px 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: 'transparent';
    a {
      text-align: center;
      color: #D7D7D7;
      width: 100%;
      display: inline-block;
      font-size: 25px;
      font-family: 'Noway Bold';
      letter-spacing: 1px;
      margin-bottom: 40px;
    }
  }

  .appLinksWrapper {
    margin-top: 80px;
  }

  .svgTypographyWrapper {
    position: absolute;
    top: -30px;
    left: 10%;
    height: 10%;
    width: 80%;
  }
}

.selectorElem {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.appHeaderSignOutIcon {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0px 30px 30px 0px;
  font-size: 22px;
}

@media only screen and (max-width: 850px) {
  .appHeaderSignOutIcon {
    display: block;
  }
}

@media only screen and (max-width: 670px) {
  .sidebarWrapper {
    width: 70%;
  }

  .sidebarInner {
    .svgTypographyWrapper {
      position: absolute;
      top: -12px;
      left: 10%;
      height: 8%;
      width: 80%;
    }
  }
}


@media only screen and (max-width: 470px) {
  .sidebarInner {
    .appLink {
      a {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 395px) {
  .sidebarInner {
    .svgTypographyWrapper {
      position: absolute;
      top: -6px;
      left: 10%;
      height: 5%;
      width: 80%;
    }
    .appLink {
      a {
        font-size: 15px;
      }
    }
  }
}